var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (t, e) {
  var s = s || {};
  "function" == typeof s && s.amd ? s([], e) : exports = e();
}(exports, function () {
  var t,
      e = 0,
      s = {},
      i = {},
      r = (t = window.MutationObserver || window.WebKitMutationObserver, function (e, s) {
    e && 1 === e.nodeType && (t ? new t(function (t, e) {
      s(t);
    }).observe(e, {
      childList: !0,
      subtree: !1
    }) : window.addEventListener && e.addEventListener("DOMNodeInserted", s, !1));
  });

  function a(t, e) {
    if (!t) return this || _global;
    e = e || {}, (this || _global).parentElm = t, (this || _global).uid = e.uid, (this || _global).settings = {
      selector: "*",
      callbacks: {}
    }, Object.assign((this || _global).settings, e), this.setup(), r((this || _global).parentElm, (this || _global).setup.bind(this || _global)), this.bindEvents();
  }

  return a.prototype = {
    namespace: "dragsort",

    setup() {
      [...(this || _global).parentElm.childNodes].forEach(t => {
        if (1 != t.nodeType) return t.parentNode.removeChild(t);
        t.matches((this || _global).settings.selector) && (t.draggable = !0);
      }), (this || _global).gap = this.getItemsGap((this || _global).parentElm.firstElementChild);
    },

    throttle(t, e) {
      var s = !1,
          i = this || _global;
      return function (r) {
        s || (t.call(i, r), s = !0, setTimeout(() => s = !1, e));
      };
    },

    getDraggableElm(t) {
      if (!t.closest) return null;
      var e = t.closest("[draggable=\"true\"]");
      return (this || _global).uid == s.uid ? e : null;
    },

    dragstart(t, e) {
      s = this || _global;
      var i,
          r = this.getDraggableElm(e);
      r ? ((this || _global).source = this.getInitialState(), (this || _global).target = this.getInitialState(), i = r.getBoundingClientRect(), (this || _global).source.elm = r, (this || _global).source.idx = this.getNodeIndex(r), (this || _global).source.size.width = i.width, (this || _global).source.size.height = i.height, t.dataTransfer.effectAllowed = "move", (this || _global).settings.callbacks.dragStart && (this || _global).settings.callbacks.dragStart((this || _global).source.elm, t), setTimeout((this || _global).afterDragStart.bind(this || _global))) : s = {};
    },

    afterDragStart() {
      var t = "vertical" == (this || _global).settings.mode ? "height" : "width";
      (this || _global).parentElm.classList.add(`${(this || _global).namespace}--dragStart`), (this || _global).source.elm.style[t] = (this || _global).source.size[t] + "px", (this || _global).source.elm.classList.add(`${(this || _global).namespace}--dragElem`);
    },

    dragover(t) {
      t.preventDefault(), t.stopPropagation();
      var e = t.target;

      if ((e = this.getDraggableElm(e)) && (this || _global).target) {
        var s = (this || _global).target.elm,
            i = (this || _global).target.hoverDirection;
        t.dataTransfer.dropEffect = "move", (this || _global).target.hoverDirection = this.getTargetDirection(t), s == e && i == (this || _global).target.hoverDirection || this.directionAwareDragEnter(t, e);
      }
    },

    dragenter(t, e) {
      (e = this.getDraggableElm(e)) && (this || _global).target && this.isValidElm(e) && (this || _global).source.elm != e && (this || _global).source.elm && ((this || _global).target.bounding = e.getBoundingClientRect());
    },

    directionAwareDragEnter(t, e) {
      var s;
      t.preventDefault(), t.stopPropagation(), t.dataTransfer.dropEffect = "none", this.isValidElm(e) && (this || _global).source.elm != e && (this || _global).source.elm && (t.dataTransfer.dropEffect = "move", this.cleanupLastTarget(), (this || _global).target.elm = e, (this || _global).target.idx = this.getNodeIndex(e), e.classList.add("over"), s = Math.abs((this || _global).target.idx - (this || _global).source.idx), (this || _global).source.elm.classList.toggle(`${(this || _global).namespace}--hide`, s > 0), "vertical" == (this || _global).settings.mode ? (this || _global).target.elm.style[(this || _global).target.hoverDirection ? "marginBottom" : "marginTop"] = (this || _global).source.size.height + (this || _global).gap + "px" : (this || _global).target.elm.style[(this || _global).target.hoverDirection ? "marginRight" : "marginLeft"] = (this || _global).source.size.width + (this || _global).gap + "px");
    },

    dragend(t) {
      if (clearTimeout((this || _global).dragoverTimeout), (this || _global).dragoverTimeout = null, (this || _global).parentElm.classList.remove(`${(this || _global).namespace}--dragStart`), !this.isValidElm((this || _global).target.elm)) return this.cleanup();
      var e = (this || _global).target.hoverDirection ? (this || _global).target.elm.nextElementSibling : (this || _global).target.elm;
      return (this || _global).source.elm != (this || _global).target.elm && (this || _global).target.elm && ((this || _global).target.elm.classList.add(`${(this || _global).namespace}--noAnim`), this.cleanup(), (this || _global).parentElm.insertBefore((this || _global).source.elm, e)), (this || _global).source.elm && (this || _global).source.elm.classList.remove(`${(this || _global).namespace}--dragElem`, `${(this || _global).namespace}--hide`), (this || _global).settings.callbacks.dragEnd && (this || _global).settings.callbacks.dragEnd((this || _global).source.elm), this || _global;
    },

    isTargetLastChild() {
      return (this || _global).parentElm.lastElementChild == (this || _global).target.elm;
    },

    getTargetDirection(t) {
      if ((this || _global).target.bounding) return "vertical" == (this || _global).settings.mode ? t.pageY > (this || _global).target.bounding.top + (this || _global).target.bounding.height / 2 ? 1 : 0 : t.pageX > (this || _global).target.bounding.left + (this || _global).target.bounding.width / 2 ? 1 : 0;
    },

    getNodeIndex(t) {
      for (var e = 0; t = t.previousSibling;) 3 == t.nodeType && /^\s*$/.test(t.data) || e++;

      return e;
    },

    isValidElm(t) {
      return t && t.nodeType && t.parentNode == (this || _global).parentElm;
    },

    cleanup() {
      s = {}, [...(this || _global).parentElm.children].forEach(t => {
        t.removeAttribute("style"), setTimeout(() => {
          t.classList.remove(`${(this || _global).namespace}--over`, `${(this || _global).namespace}--noAnim`, `${(this || _global).namespace}--dragElem`);
        }, 50);
      });
    },

    cleanupLastTarget() {
      (this || _global).target.elm && ((this || _global).target.elm.classList.remove(`${(this || _global).namespace}--hide`, `${(this || _global).namespace}--over`), (this || _global).target.elm.removeAttribute("style"));
    },

    getInitialState: () => ({
      elm: null,
      size: {}
    }),

    getItemsGap(t) {
      var e = getComputedStyle(t),
          s = getComputedStyle(t.parentNode),
          i = "vertical" == (this || _global).settings.mode,
          r = parseInt(s.gap) || 0;
      return parseInt(e["margin" + (i ? "Top" : "Left")]) + parseInt(e["margin" + (i ? "Bottom" : "Right")]) + r;
    },

    bindEvents(t) {
      for (var e in (this || _global).listeners = (this || _global).listeners || {
        dragstart: t => this.dragstart(t, t.target),
        dragenter: t => this.dragenter(t, t.target),
        dragend: t => this.dragend(t, t.target),
        dragover: this.throttle((this || _global).dragover, 350)
      }, (this || _global).listeners) (this || _global).parentElm[t ? "removeEventListener" : "addEventListener"](e, (this || _global).listeners[e]);
    },

    destroy() {
      this.cleanup(), this.bindEvents(!0), delete i[(this || _global).uid];
    }

  }, function (t, s) {
    return i[++e] = t.DragSort ? i[t.DragSort] : new a(t, { ...s,
      uid: e
    }), t.DragSort = e, i[e];
  };
});
export default exports;
export const DragSort = exports.DragSort;